import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Layout from '../containers/layout';
import PageTransition from '../components/common/page-transition';
import PaddedPage from '../components/common/padded-page';

const Resume = () => (
  <Layout>
    <PageTransition>
      <PaddedPage>
        <div>resume</div>
        <div>Download CV PDF</div>
      </PaddedPage>
    </PageTransition>
  </Layout>
);

export default Resume;
