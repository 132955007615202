import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  box-sizing: border-box;
  padding: 10px 20px;
  @media only screen and (min-width: 450px) {
    padding: 10px 30px;
  }
  @media only screen and (min-width: 690px) {
    padding: 10px 40px;
  }
`;

const PagePadding = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth}px;
`;

const PaddedPage = ({ children }) => (
  <Container>
    <PagePadding>
      {children}
    </PagePadding>
  </Container>
);

PaddedPage.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PaddedPage;
